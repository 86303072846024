@import 'src/variables.scss';
.App {
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: stretch;

  .footer {
    width: 100%;
  }

  > .content {
    min-height: 0;
    flex: 1 1 auto;
    display: flex;
    justify-content: stretch;
    flex-direction: column;
    background-color: black;
    > div {
      min-height: 0;
      flex: 20 1 auto;
      overflow: auto;    
    }
    div[class*='sidenav'] { 
      flex: 1 1 auto;
      max-width: 100%;
      max-height: 250px;
      background-color: $secondary-color;
    }
  }

  @media only screen and (min-width: 600px) {
    div[class*='content'] { flex-direction: row; }
    > .content div[class*='sidenav'] { max-width: 30%; max-height: 100%; }
  }
 
}
